@tailwind base;
@tailwind components;
@tailwind utilities;

.spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
  
  & .path {
    stroke: #232323;
    stroke-width: 10px;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% { transform: rotate(360deg); }
}

@keyframes dash {
  0% { stroke-dasharray: 1, 150; stroke-dashoffset: 0; }
  50% { stroke-dasharray: 90, 150; stroke-dashoffset: -35; }
  100% { stroke-dasharray: 90, 150; stroke-dashoffset: -124; }
}

@font-face {
  font-family: 'Neue Haas Display';
  src: url(./../public/fonts/NeueHaasDisplayLight.ttf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Display Bold';
  src: url(./../public/fonts/NeueHaasDisplayBold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: 'DINAlternative';
  src: url("./static/fonts/DINAlternateBold.ttf");
}

@font-face {
  font-family: 'SFUITextMedium';
  src: url("./static/fonts/SFUITextMedium.otf");
}

@font-face {
  font-family: 'SFUITextRegular';
  src: url("./static/fonts/SFUITextRegular.otf");
} */